<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Желаемая зп клинера</v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular v-if="loading" indeterminate/>
        <v-form ref="form" v-else>
          <v-row class="row-d-block mb-2">
            <v-text-field v-model="item.value" outlined
                        :rules="[v=>!!v || 'Укажите желаемую зарплату']"/>
          </v-row>
          <v-btn @click="save" :loading="save_loading">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DesiredSalaryModal",
  emits: ['on-change'],
  data() {
    return {
      opened: false,
      loading: false,
      item: {}, //yearMonth: YYYY_MM, value, cleaner_id
      save_loading: false,
    }
  },
  methods: {
    open(params) {
      this.item = params;
      this.opened = true;
      this.get();
    },
    get() {
      this.loading = true;
      this.$store.state.server.request('cleaner/getDesiredSalary',this.item,(data)=>{
        this.loading = false;
        this.item.value = data.response;
      }, () => {
        this.loading = false;
      })
    },
    save() {
      this.save_loading = true;
      this.$store.state.server.request('cleaner/updateDesiredSalary', this.item, (data)=>{
        this.save_loading = false;
        this.$emit('on-change');
        this.opened = false;
        this.$root.notify('Желаемая зарплата клинера сохранена', 'success');
      }, (data)=>{
        this.save_loading = false;
        this.$root.notify(data.error, 'error');
      })
    }
  }
}
</script>

<style scoped>

</style>

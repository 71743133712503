var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-2"},[_vm._v("Статистика клинеров")]),_c('v-card-text',[_c('v-alert',{staticClass:"constants"},[_c('span',[_vm._v("Норма выручки клинера в день: ")]),_vm._v(_vm._s(_vm.$root.printCost(_vm.constants.revenuePerDay))+", "),_c('span',[_vm._v("процент: ")]),_vm._v(_vm._s(_vm.constants.cleanerPercent)+"%, "),_c('span',[_vm._v("доход: ")]),_vm._v(_vm._s(_vm.$root.printCost(_vm.constants.profitPerCleaner))+" за смену, "),_c('span',[_vm._v("длительность смены: ")]),_vm._v(_vm._s(_vm.constants.workday_duration_hours)+"ч. ")]),_c('v-row',[_c('v-col',[_c('YearMonth',{attrs:{"next-month":"1"},on:{"change":_vm.get},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Поиск..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"stripped cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.items.filter(function (item){ return item.name.toLowerCase().indexOf(_vm.search.toLowerCase().trim())>=0; }),"footer-props":{'items-per-page-options':[-1]},"loading-text":"Загрузка...","loading":_vm.loading,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"py-2 d-block"},[_c('div',{staticClass:"mb-1"},[_c('CleanerLink',{attrs:{"value":item}}),_vm._v(" "+_vm._s(item.comment)+" ")],1)])]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"params-col"},[_c('v-row',{staticClass:"py-2 align-center cursor-pointer flex-nowrap"},[_vm._v("План")]),_c('v-row',{staticClass:"py-2",staticStyle:{"white-space":"nowrap"}},[_vm._v("Оценка (не ниже "+_vm._s(_vm.constants.minRate)+")")]),_c('v-row',{staticClass:"py-2",staticStyle:{"white-space":"nowrap"}},[_vm._v("Отработала/Проставила"),(+item.desired_salary)?_c('span',[_vm._v("/План")]):_vm._e()]),_c('v-row',{staticClass:"py-2",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.openViolationModal({cleaner_id: item.n_id})}}},[_vm._v(" Нарушения "),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","x-small":""}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)],1)]}},{key:"item.week_0_sum",fn:function(ref){
var item = ref.item;
return [_c('StatisticsCol',{attrs:{"item":item,"i":0,"constants":_vm.constants},on:{"on-violation-click":function (item_id){ return _vm.openViolationModal({item_id: item_id}); }}})]}},{key:"item.week_1_sum",fn:function(ref){
var item = ref.item;
return [_c('StatisticsCol',{attrs:{"item":item,"i":1,"constants":_vm.constants},on:{"on-violation-click":function (item_id){ return _vm.openViolationModal({item_id: item_id}); }}})]}},{key:"item.week_2_sum",fn:function(ref){
var item = ref.item;
return [_c('StatisticsCol',{attrs:{"item":item,"i":2,"constants":_vm.constants},on:{"on-violation-click":function (item_id){ return _vm.openViolationModal({item_id: item_id}); }}})]}},{key:"item.week_3_sum",fn:function(ref){
var item = ref.item;
return [_c('StatisticsCol',{attrs:{"item":item,"i":3,"constants":_vm.constants},on:{"on-violation-click":function (item_id){ return _vm.openViolationModal({item_id: item_id}); }}})]}},{key:"item.week_4_sum",fn:function(ref){
var item = ref.item;
return [_c('StatisticsCol',{attrs:{"item":item,"i":4,"constants":_vm.constants},on:{"on-violation-click":function (item_id){ return _vm.openViolationModal({item_id: item_id}); }}})]}},{key:"item.totalSalary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"params-col"},[_c('v-row',{staticClass:"py-2 text-center flex-nowrap",staticStyle:{"white-space":"nowrap"}},[_c('span',{style:(("color: " + (_vm.color(_vm.calcResultTotal(item), item.desired_salary))))},[_vm._v(" "+_vm._s(_vm.calcResultTotal(item))+" ")]),(item.desired_salary)?_c('span',[_vm._v("/"+_vm._s(_vm.$root.printCost(item.desired_salary)))]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.openSetDesiredSalaryModal(item.n_id)}}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1),_c('v-row',{staticClass:"py-2 text-center"},[_c('span',{style:(("color: " + (_vm.color(_vm.calcResultFeedback(item),_vm.constants.minRate))))},[_vm._v(" "+_vm._s(_vm.calcResultFeedback(item).toFixed(2) || '---'))]),_c('span',[_vm._v("/"+_vm._s(_vm.constants.minRate))])]),_c('v-row',{staticClass:"py-2 text-center"},[_c('span',{style:(("color: " + (_vm.color(_vm.calcDaysWithOrder(item),_vm.calcResultWorkingDays(item)))))},[_vm._v(_vm._s(_vm.calcDaysWithOrder(item).toFixed(1)))]),_vm._v(" /"),_c('span',{style:(("color: " + (_vm.color(_vm.calcResultWorkingDays(item),_vm.calcTotalWorkingDays(item)))))},[_vm._v(_vm._s(_vm.calcResultWorkingDays(item).toFixed(1)))]),(_vm.calcTotalWorkingDays(item))?_c('span',[_vm._v("/"+_vm._s(_vm.calcTotalWorkingDays(item).toFixed(1)))]):_vm._e()]),_c('v-row',{staticClass:"py-2 text-center"},[_c('span',{style:(("color: " + (_vm.calcResultViolations(item) < _vm.constants.maxViolations ? 'green' : 'red')))},[_vm._v(" "+_vm._s(_vm.calcResultViolations(item)))]),_vm._v("/"+_vm._s(_vm.constants.maxViolations)+" ")])],1)]}}])})],1)],1),_c('ViolationModal',{ref:"violation_modal",on:{"on-update":_vm.get}}),_c('DesiredSalaryModal',{ref:"desired_salary_modal",on:{"on-change":_vm.get}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
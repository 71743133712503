<template>
  <div class="params-col">
    <v-row class="py-2 text-center flex-nowrap" :class="{'with-opacity': !item.weeks[i].sum}"
           style="white-space: nowrap">
      <span :style="`color: ${color(item.weeks[i].sum, item.weeks[i].needToEarnPerWeek)}`">{{ $root.printCost(item.weeks[i].sum)}}</span>
      <span v-if="item.desired_salary">/{{ $root.printCost(item.weeks[i].needToEarnPerWeek )}}</span>
    </v-row>
    <v-row class="py-2 text-center" :class="{'with-opacity': !+item.weeks[i].feedback.avg > 0}"
           :style="`color: ${color(item.weeks[i].feedback.avg, constants.minRate)}`"
    >
      {{
        +item.weeks[i].feedback.avg > 0 ? item.weeks[i].feedback.avg.toFixed(2) : '---'
      }}
    </v-row>
    <v-row class="py-2 text-center" :class="{'with-opacity': !item.weeks[i]}">
      <span :style="`color: ${color(item.weeks[i].worked_shifts, item.weeks[i].set_working_shifts)}`">{{ item.weeks[i].worked_shifts }}</span>
      /<span :style="`color: ${color(item.weeks[i].set_working_shifts, item.weeks[i].needToWorkPerWeek)}`">{{ item.weeks[i].set_working_shifts }}</span>
      <span v-if="item.weeks[i].needToWorkPerWeek">/{{item.weeks[i].needToWorkPerWeek}}</span>
    </v-row>
    <v-menu ref="menu"
            :close-on-content-click="true"
            transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-row v-bind="attrs"
               v-on="on"
               class="py-2 text-center" :class="{'with-opacity': !item.weeks[i].violations.length}"
        >
          {{ item.weeks[i].violations ? item.weeks[i].violations.length : '---' }}
        </v-row>
      </template>
      <v-list v-if="item.weeks[i] && item.weeks[i].violations">
        <v-list-item v-for="item in item.weeks[i].violations" :key="item.id"
                     @click="openViolationModal(item.id)">{{ item.comment }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "StatisticsCol",
  props: ['item', 'i', 'constants'],
  emits: ['on-violation-click'],
  methods: {
    openViolationModal(violation_id) {
      this.$emit('on-violation-click', violation_id);
    },
    color(currentValue, targetValue) {
      if (currentValue === 0) return '';
      else if (currentValue < targetValue) return 'red';
      else return 'green';
    }
  }
}
</script>

<style scoped>
.params-col {
  /*width: 170px;*/
}

.with-opacity {
  opacity: 0.5;
}
</style>

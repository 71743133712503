<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Нарушение</v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular v-if="loading" indeterminate/>
        <v-form ref="form" v-else>
          <v-row class="row-d-block mb-2">
            <v-subheader>Опишите нарушение</v-subheader>
            <v-textarea v-model="item.comment" outlined
                        :rules="[v=>!!v || 'Укажите нарушение']"/>
          </v-row>
          <v-row class="row-d-block mb-4">
            <v-subheader>Дата нарушения</v-subheader>
            <Date v-model="item.date"/>
          </v-row>
          <v-btn @click="set" :loading="set_loading">Сохранить</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Date from "@/components/Date";
export default {
  name: "ViolationModal",
  components: {Date},
  emits: ['on-update'],
  data() {
    return {
      opened: false,
      item: {},
      loading: false,
      set_loading: false,

    }
  },
  methods: {
    open(params = {}) {
      this.item = {cleaner_id: params.cleaner_id, date: this.$moment().format('YYYY-MM-DD'),};
      this.opened = true;
      if (params.item_id) this.get(params.item_id);
    },
    get(item_id) {
      this.loading =true;
      this.$store.state.server.request(`violation/get/${item_id}`, {}, (data) => {
        this.loading =false;
        this.item = data.response;
      }, ()=>{
        this.loading =false;
      })
    },
    set() {
      if (this.$refs.form.validate()) {
        let method = this.item.id ? `update/${this.item.id}` : 'create';
        this.set_loading = true;
        this.$store.state.server.request(`violation/${method}`, this.item, () => {
          this.set_loading = false;
          this.$root.notify(this.item.id ? 'Нарушение сохранено' : 'Нарушение создано', 'success');
          this.$emit('on-update');
          this.opened = false;
        }, (data) => {
          this.set_loading = false;
          this.$root.notify(data.error, 'error');
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title class="mb-2">Статистика клинеров</v-card-title>
        <v-card-text>
          <v-alert class="constants">
            <span>Норма выручки клинера в день: </span>{{$root.printCost(constants.revenuePerDay)}},
            <span>процент: </span>{{constants.cleanerPercent}}%,
            <span>доход: </span>{{ $root.printCost(constants.profitPerCleaner) }} за смену,
            <span>длительность смены: </span>{{constants.workday_duration_hours}}ч.
          </v-alert>
          <v-row>
            <v-col>
              <YearMonth next-month="1" v-model="date" @change="get"/>
            </v-col>
            <v-col>
              <v-text-field outlined placeholder="Поиск..." v-model="search"/>
            </v-col>
          </v-row>
          <v-data-table
              :headers="headers"
              :items="items.filter(item=>item.name.toLowerCase().indexOf(search.toLowerCase().trim())>=0)"
              :footer-props="{'items-per-page-options':[-1]}"
              class="stripped cursor-pointer"
              loading-text="Загрузка..."
              :loading="loading"
              mobile-breakpoint="0">
            <template v-slot:item.name="{item}">
              <v-row class="py-2 d-block">
                <div class="mb-1">
                  <CleanerLink :value="item"/>
                  {{item.comment}}
                </div>
              </v-row>
            </template>
            <template v-slot:item.params="{item}">
              <div class="params-col">
                <v-row class="py-2 align-center cursor-pointer flex-nowrap">План</v-row>
                <v-row class="py-2" style="white-space: nowrap">Оценка (не ниже {{ constants.minRate }})</v-row>
                <v-row class="py-2" style="white-space: nowrap">Отработала/Проставила<span v-if="+item.desired_salary">/План</span>
                  <!--(но не менее 8 часов интервал рабочий)--></v-row>
                <v-row class="py-2" style="white-space: nowrap" @click="openViolationModal({cleaner_id: item.n_id})">
                  Нарушения
                  <v-btn class="ml-1" icon x-small>
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-row>
              </div>
            </template>
            <template v-slot:item.week_0_sum="{item}">
              <StatisticsCol :item="item" :i="0" @on-violation-click="item_id=>openViolationModal({item_id})"
                             :constants="constants"/>
            </template>
            <template v-slot:item.week_1_sum="{item}">
              <StatisticsCol :item="item" :i="1" @on-violation-click="item_id=>openViolationModal({item_id})"
                             :constants="constants"/>
            </template>
            <template v-slot:item.week_2_sum="{item}">
              <StatisticsCol :item="item" :i="2" @on-violation-click="item_id=>openViolationModal({item_id})"
                             :constants="constants"/>
            </template>
            <template v-slot:item.week_3_sum="{item}">
              <StatisticsCol :item="item" :i="3" @on-violation-click="item_id=>openViolationModal({item_id})"
                             :constants="constants"/>
            </template>
            <template v-slot:item.week_4_sum="{item}">
              <StatisticsCol :item="item" :i="4" @on-violation-click="item_id=>openViolationModal({item_id})"
                             :constants="constants"/>
            </template>
            <template v-slot:item.totalSalary="{item}">
              <div class="params-col">
                <v-row class="py-2 text-center flex-nowrap" style="white-space: nowrap">
                  <span :style="`color: ${color(calcResultTotal(item), item.desired_salary)}`">
                  {{ calcResultTotal(item) }}
                  </span>
                  <span v-if="item.desired_salary">/{{ $root.printCost(item.desired_salary) }}</span>
                  <v-btn @click="openSetDesiredSalaryModal(item.n_id)" class="ml-2" icon x-small>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-row>
                <v-row class="py-2 text-center">
                  <span :style="`color: ${color(calcResultFeedback(item),constants.minRate)}`">
                    {{ calcResultFeedback(item).toFixed(2) || '---' }}</span>
                  <span>/{{constants.minRate}}</span>
                </v-row>
                <v-row class="py-2 text-center">
                  <span :style="`color: ${color(calcDaysWithOrder(item),calcResultWorkingDays(item))}`">{{
                      calcDaysWithOrder(item).toFixed(1)
                    }}</span>
                  /<span :style="`color: ${color(calcResultWorkingDays(item),calcTotalWorkingDays(item))}`">{{
                    calcResultWorkingDays(item).toFixed(1)
                  }}</span>
                  <span v-if="calcTotalWorkingDays(item)">/{{ calcTotalWorkingDays(item).toFixed(1) }}</span>
                </v-row>
                <v-row class="py-2 text-center">
                  <span :style="`color: ${calcResultViolations(item) < constants.maxViolations ? 'green' : 'red'}`">
                    {{ calcResultViolations(item) }}</span>/{{constants.maxViolations}}
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <ViolationModal ref="violation_modal" @on-update="get"/>
      <DesiredSalaryModal ref="desired_salary_modal" @on-change="get"/>
    </v-container>
  </v-app>
</template>

<script>
import YearMonth from "@/components/YearMonth";
import StatisticsCol from "@/views/cleaners/components/StatisticsCol";
import ViolationModal from "@/views/cleaners/components/ViolationModal";
import CleanerLink from "@/components/CleanerLink";
import DesiredSalaryModal from "@/views/cleaners/components/DesiredSalaryModal";

export default {
  name: "Statistics",
  components: {DesiredSalaryModal, CleanerLink, ViolationModal, StatisticsCol, YearMonth},
  data() {
    return {
      date: [this.$moment().format('YYYY'), this.$moment().format('MM')],
      headers: [
        {text: 'Клинер', value: 'name'},
        {text: '---', value: 'params'},
        {text: '1 неделя', value: 'week_0_sum'},
        {text: '2 неделя', value: 'week_1_sum'},
        {text: '3 неделя', value: 'week_2_sum'},
        {text: '4 неделя', value: 'week_3_sum'},
        {text: '5 неделя', value: 'week_4_sum'},
        {text: 'Итого', value: 'totalSalary'},
      ],
      loading: false,
      items: [],
      search: '',
      constants: {}
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.$store.state.server.request('cleaner/salaryStats', {year: this.date[0], month: this.date[1]}, (data) => {
        this.$router.push({query: {year: this.date[0], month: this.date[1]}}).catch(() => {
        });
        this.loading = false;
        this.items = data.response;
        this.constants = data.constants;
      }, () => {
        this.loading = false;
      })
    },
    openViolationModal(params) {
      this.$refs.violation_modal.open(params);
    },
    calcResultTotal(item) {
      if (item.weeks && Object.keys(item.weeks).length) {
        return this.$root.printCost(Object.entries(item.weeks).reduce((acc, v) => acc + (v[1] ? +v[1].sum : 0), 0));
      } else return this.$root.printCost(0);
    },
    calcResultFeedback(item) {
      let avgFeedbacks = Object.entries(item.weeks)
          .map(([k, v]) => v && v.feedback ? +v.feedback.avg : 0);
      if (item.weeks && Object.keys(item.weeks).length && avgFeedbacks.filter(v => v).length) {
        return avgFeedbacks
                .reduce((acc, v) => acc + v, 0)
            / avgFeedbacks.filter(v => v).length;
      } else return 0;
    },
    calcResultViolations(item) {
      if (item.weeks && Object.keys(item.weeks).length) {
        return Object.entries(item.weeks).map(([k, v]) => v && v.violations ? v.violations.length : 0)
            .reduce((acc, v) => acc + v, 0)
      } else return 0;
    },
    calcResultWorkingDays(item) {
      if (item.weeks && Object.keys(item.weeks).length) {
        return Object.entries(item.weeks).map(([k, v]) => v && v.set_working_shifts ? +v.set_working_shifts : 0)
            .reduce((acc, v) => acc + v, 0)
      } else return 0;
    },
    calcDaysWithOrder(item) {
      if (item.weeks && Object.keys(item.weeks).length) {
        return Object.entries(item.weeks).map(([k, v]) => v && v.worked_shifts ? +v.worked_shifts : 0)
            .reduce((acc, v) => acc + v, 0)
      } else return 0;
    },
    calcTotalWorkingDays(item) {
      if (item.weeks && Object.keys(item.weeks).length) {
        return Object.entries(item.weeks).map(([k, v]) => v && v.needToWorkPerWeek ? +v.needToWorkPerWeek : 0)
            .reduce((acc, v) => acc + v, 0)
      } else return 0;
    },
    setQueryParams() {
      if (this.$route.query.year) {
        this.date[0] = this.$route.query.year;
      }
      if (this.$route.query.month) {
        this.date[1] = this.$route.query.month;
      }
    },
    openSetDesiredSalaryModal(cleaner_id) {
      this.$refs.desired_salary_modal.open({cleaner_id, yearMonth: `${this.date[0]}_${this.date[1]}`});
    },
    color(currentValue, targetValue) {
      if (currentValue === 0) return '';
      else if (currentValue < targetValue) return 'red';
      else return 'green';
    }
  },
  beforeMount() {
    this.setQueryParams();
  },
  mounted() {
    this.get();
  }
}
</script>

<style scoped>
.constants span {
  opacity: .7;
}
</style>
